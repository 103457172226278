:root {
  --fontFamily: Inter !important;
}

.top_categories_box {
  /* background-color: #212121!important; */
  width: 100px !important;
  border: 1px solid #fff !important;
  border-radius: 10px !important;
  text-align: center !important;
}

.top_categories_collection {
  color: #ffffff !important;
  font-size: 52px !important;
  font-weight: 700 !important;
  height: 52px !important;
  letter-spacing: -1.73px !important;
}
.chart_leftside-aligment {
  width: 100% !important;

  /* border: 4px solid white!important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* flex-direction: column */
}
.chart_leftside_container {
  width: 100% !important;
  /* height: 50vh !important; */
  /* border: 4px solid white!important; */
  display: flex !important;
  justify-content: center !important;
  /* align-items: center!important; */
  /* flex-direction: column */
}
.top_categories_container_stats {
  /* width: 91%!important; */
  width: 100% !important;

  align-items: center !important;
  /* background-color: red!important; */
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 30px !important;
}

.collection_percentage {
  color: #8f92a1 !important;
  font-size: 32px !important;
  font-weight: 800 !important;
  letter-spacing: -1.73px !important;
}

.middle_categories_container {
  display: flex !important;
  /* width: 91% !important; */
  margin-top: 20px !important;
}

.middle_lefside_element {
  width: auto !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  flex-direction: column !important;
  cursor: pointer !important;
}

.middle_leftside_container {
  /* width: 50% !important; */
  /* height: 50vh !important; */
  /* background:green!important; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 3px solid white !important;
  border-radius: 15px !important;
  flex-direction: column !important;
}
.download_text {
  font-size: 32px !important;
  color: white !important;
  font-weight: 900 !important;
  font-family: var(--fontFamily) !important;
}
.tracks_songs_text{
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  white-space: nowrap;
  color: #ffffff;
  font-size: 22px;
  font-family: var(--fontFamily) !important;
  max-width: 300px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
 
}

/* Top downloaders */
.disable-scrollbars::-webkit-scrollbar {
  background: transparent !important; /* Chrome/Safari/Webkit */
  width: 0px !important;
}

.disable-scrollbars {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE 10+ */
  overflow-y: auto !important;
  overflow-x: hidden !important;
  /* height adding*/
  max-height: 80% !important;
  width: 100% !important;
}

.download-details-disable-scrollbars {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE 10+ */
  overflow-y: auto !important;
  overflow-x: hidden !important;
  /* height adding*/
  max-height: 75% !important;
  width: 100% !important;
}

.download-details-disable-scrollbars::-webkit-scrollbar {
  background: transparent !important; /* Chrome/Safari/Webkit */
  width: 0px !important;
}

.linearProgressDiv {
  width: 100% !important;
  height: 12px !important;
  padding: 3px 8px !important;
  background-color: #212121 !important;
  border-radius: 10px !important;
}

.progressbar {
  background: #212121 !important;
  width: 100% !important;
  height: 10px !important;
}

.btnGroup {
  width: 100% !important;
  /* // height: min-content */
}

.topDownloaders {
  font-size: 24px !important;
  color: #3959f9 !important;
  font-weight: 800 !important;
  font-family: var(--fontFamily) !important;
}

.topDownloadersSpan {
  font-size: 24px !important;
  color: white !important;
  font-weight: 800 !important;
  font-family: var(--fontFamily) !important;
}

.downloaderListTopGrid {
  display: flex !important;
  padding: 12px 0px !important;
}

.downloaderListSecondGrid {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.downloaderListSpaceAround {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.indexStyle {
  color: white !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.downloadArtWork {
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
}

.downloadArtWork-downloads {
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
}

.downloadArtWorkGrid {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around !important;
  padding-right: 8px !important;
}

.downloadArtName {
  font-size: 14px !important;
  font-weight: bold !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
}

.verified-snapshot {
  width: 13px;
  height: 13px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 6px !important;
}

.downloadTypeName {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #8f92a1 !important;
}

.noData {
  font-family: var(--fontFamily) !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: -1px !important;
  text-align: center !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  color: white !important;
  margin: 25% 0 0 0 !important;
}

.openDownloaders {
  background: #141414 !important;
  border-radius: 7px !important;
  display: flex !important;
  padding: 12px 0px !important;
  cursor: pointer !important;
}

.closeDownloaders {
  background: transparent !important;
  border-radius: none !important;
  display: flex !important;
  padding: 12px 0px !important;
  cursor: pointer !important;
}

/* Download detaiuls */

.chipCss {
  background-color: #212121 !important;
  color: white !important;
  font-family: var(--fontFamily) !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.artistImage {
  width: 75px !important;
  height: 75px !important;
  border-radius: 10px !important;
  border: 1px !important;
}
.displayPd {
  display: flex !important;
  padding: 10px 0px !important;
}
.imgIcon {
  width: 60px !important;
  height: 60px !important;
  cursor: pointer !important;
}
.displayBetween {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.displayMargin {
  /* margin-bottom: 15px !important; */
  min-height: 10% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  /* new changes */
  margin-top: 3% !important;
}
.artistGrid {
  font-family: var(--fontFamily) !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: white !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 220px !important;
}
.topDownloaders-downloads {
  font-size: 24px !important;
  color: #3959f9 !important;
  font-weight: 800 !important;
  font-family: var(--fontFamily) !important;
}
.topDownloadersSpan-downloads {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #8f92a1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 220px !important;
}
.downloaderListTopGrid-downloads {
  display: flex !important;
  flex-direction: column !important;
  /* padding-left: 7px !important; */
}
.downloaderListSecondGrid-downloads {
  display: flex !important;
}
.mBStyle {
  margin-bottom: 15px !important;
}

.downloadArtWorkGrid-downloads {
  display: flex !important;
  max-height: 100% !important;
}

.downloadHeightAdjust {
  min-height: 15% !important;
  align-items: center !important;
  display: flex !important;
}
.downloadArtName-downloads {
  font-size: 22px !important;
  font-weight: bold !important;
  color: white !important;
  font-family: var(--fontFamily) !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 285px !important;
}
.downloadTypeName-downloads {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #8f92a1 !important;
  font-family: var(--fontFamily) !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 285px !important;
}
.downloadColorBlue {
  color: #3959f9 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.noData-downloads {
  font-family: var(--fontFamily) !important;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: -1px !important;
  text-align: center !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  color: white !important;
}
.typeHead {
  font-family: var(--fontFamily) !important;
  font-size: 22px !important;
  font-weight: 800 !important;
  line-height: 19px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: white !important;
}

@media screen and (max-width: 599px) {
  .downloadArtWork {
    width: 50px !important;
    height: 50px !important;
  }
}

/* Mobile  */
.mobileDownloaderBack {
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #8f92a1 !important;
  margin-bottom: 2% !important;
}

/* StatsBox */

.plays-stats {
  font-family: var(--fontFamily) !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  line-height: 22px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
  color: white !important;
}

.stats-overall {
  font-family: var(--fontFamily) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 15px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #8f92a1 !important;
}

.stats-lastWeek {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
  color: #8f92a1;
}

.tab-content-genre {
  font-family: var(--fontFamily) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: white !important;
}

.stats-divFiller {
  width: 60px !important;
  height: 60px !important;
  border-radius: 60px !important;
  border: 3px solid #212121 !important;
  background-color: #141414 !important;
  text-align: center !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.stats-icon {
  width: 16.5px !important;
  height: 21px !important;
}
.music__button{
  mix-blend-mode: hard-light !important;
  max-width: 15px;
  max-height: 24px;
}

.stats-outerDiv {
  height: 120px !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  border: solid 5px #212121 !important;
  background-color: black !important;
  padding: 10px !important;
  margin-left: auto !important;
  cursor: pointer !important;
  margin-right: 10px !important;
  justify-content: space-between !important;
  margin-bottom: 10px !important;
}

.stats-grid-container {
  padding: 0px 20px 20px 20px !important;
}

.ctrl-snapshot-grid {
  display: flex !important;
  justify-content: space-between !important;
  padding: 20px 60px 20px 20px !important;
}

.ctrl-font {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: white !important;
}

.stats-leftDiv {
  text-align: right !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: right !important;
  align-items: start !important;
  margin-left: 5px !important;
}

.stats-childGrid {
  display: flex !important;
  align-items: center !important;
}

.stats-overallGrid {
  display: flex !important;
  flex-direction: column !important;
  align-items: end !important;
}

.download-split-icon {
  width: 36px !important;
  height: 33px !important;
}

.top-downloaders {
  font-family: var(--fontFamily) !important;
  font-size: 20px !important;
  font-weight: 900 !important;
  text-align: left !important;
  color: #3959f9 !important;
}

.ctrl-tab-parent {
  margin-left: 15px !important;
}

.snapshot-top-grid {
  padding: 0px 60px 20px 20px !important;
}

.ctrl-top-main-grid {
  background-color: black;
  height: 100vh;
  /* overflow-y: scroll; */
}

.ctrl-top-main-grid-snapshot {
  background-color: black;
  height: 100vh;
  /* overflow-y: auto; */
}
.ctrl-top-main-grid::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.ctrl-top-main-grid::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important;
}

.ctrl-top-main-grid::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
}
.ctrl-top-main-grid-snapshot::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.ctrl-top-main-grid-snapshot::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important;
}

.ctrl-top-main-grid-snapshot::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
}
.tracks_image {
  height: 148px;
  width:170px;
  /* min-width: 148px; */
  border-radius: 12px !important;
  margin-bottom: 4px !important;
}
.video_image_tracks{
  height: 193px;
  width: 380px;
  cursor: pointer;
}
.image_border{
  width: 380px;
  height: 76.275px;
  border-radius: 8px;
  border: 0px solid #000;
  padding: 20px;
  background: linear-gradient(180deg, rgba(9, 9, 9, 0.00) 0%, rgba(9, 9, 9, 0.78) 47.13%, #090909 100%)
}
.statusbuttonsactive {
  font-size: 16px;
  font-weight: 900;
  background-color: #274af4;
  line-height: 1.36;
  color:#ffff;
  border-radius: 35px;
  text-transform: capitalize;
  width: 137px
}
.statusbuttons {
  font-size: 16px;
  font-weight: 900;
  /* background-color: #274af4; */
  line-height: 1.36;
  color:#ffff;
  border-radius: 35px;
  text-transform: capitalize;
  width: 137px
}
.edit_image{
  border-radius: 120px;
  border: 2px solid #141414;
  width: 60px;
  height: 60px;
}
.playlist_canvas_width_ctrl{
  height: 50px;
  position: relative;
}
.no_data{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #fff;
  padding: 5,
}

.videoPlayerWrapper_in_playlist {
  height: 350px !important;
}
.videoplayer_background_playlist {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: end;
  border-radius: 15px;
  top:2%
}
.videos_palylist_features_icons {
  position: absolute;
  left: 91%;
  top:2%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  visibility: hidden;
}


.disable-select {
  user-select: none;
  -webkit-touch-callout: none;
}

@media screen and (max-width: 662px) {
  .top_categories_container_stats {
    overflow-x: auto !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .top_categories_container_stats > * {
    flex: 0 0 auto !important;
  }
}

@media screen and (max-width: 600px) {
  .ctrl-tab-parent {
    margin-left: 0px !important;
  }
  .snapshot-top-grid {
    padding: 0px 20px 20px 20px !important;
  }
  .stats-grid-container {
    padding: 0px 20px 20px 20px !important;
  }
  .tracks_songs_text{
    max-width: 170px;
  }

  .ctrl-snapshot-grid {
    padding: 0px 20px 20px 20px !important;
  }
  .stats-outerDiv {
    height: 100% !important;
  }

  .middle_leftside_container {
    border: none !important;
    border-radius: 0px !important;
    height: auto !important;
    padding: 20px 0px !important;
  }

  .top_categories_container_stats::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
  .middle_lefside_element {
    padding: 10px 0px !important;
  }
  .stats-outerDiv {
    border: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1140px) {
  .downloadArtWork-downloads {
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
  }

  .imgIcon {
    width: 50px !important;
    height: 50px !important;
    cursor: pointer !important;
  }
}

@media screen and (max-width: 925px) {
  .artistGrid {
    font-size: 20px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 146px !important;
  }
  .topDownloadersSpan-downloads {
    font-size: 14px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 146px !important;
  }
  .tracks_image {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .artistGrid {
    padding-left: 10px !important;
  }
  .topDownloadersSpan-downloads {
    padding-left: 10px !important;
  }
  .imgIcon {
    width: 40px !important;
    height: 40px !important;
    cursor: pointer !important;
  }
  .no_data{
    padding: 5px !important;
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 860px) and (max-width: 1250px) {
  .artistImage {
    width: 65px !important;
    height: 65px !important;
    border-radius: 10px !important;
    border: 1px !important;
  }
  .downloadArtName-downloads {
    font-size: 20px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 240px !important;
  }
  .downloadTypeName-downloads {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 240px !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1160px) {
  .downloads-tracks-name {
    padding-left: 12px !important;
  }
}
@media screen and (max-width: 960px) {
  .snapshot-top-grid {
    padding: 0px 20px 20px 20px !important;
  }
  .ctrl-snapshot-grid {
    padding: 0px 20px 20px 20px !important;
  }
  .middle_leftside_container{
  margin: 12px !important;
  }
}

@media screen and (max-width: 860px) {
  .artistImage {
    width: 60px !important;
    height: 60px !important;
    border-radius: 10px !important;
    border: 1px !important;
  }

  .downloadArtName-downloads {
    font-size: 20px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 165px !important;
  }

  .downloadTypeName-downloads {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 165px !important;
  }
}

@media screen and (max-width: 820px) {
  .downloads-tracks-name {
    padding-left: 12px !important;
  }
  .splittexthide{
    display: none;
  }
  .weekpercentbox{
    display: none !important;
  }
  .two_dots_image{
    display: block !important;
  }
 
}

.custom-bar {
  transition: background-color 0.3s ease;
}

.custom-bar:hover {
  background-color: #3959f9 !important;
}

.snapshot-two-weeks--grid {
  background-color: rgb(5, 5, 5) !important;
  border-color: rgb(28, 28, 28) !important;
  border-width: 2px !important;
  border-radius: 20px !important;
  margin-bottom: 20px !important;
  display: flex !important;
}

.snapshot-two-weeks-data {
  color: rgb(100, 100, 100) !important;
  font-family: var(--fontFamily) !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  text-align: left !important;
  width: max-content !important;
  border: 1px solid rgb(33, 33, 33) !important;
  padding: 1px 16px !important;
  border-radius: 19px !important;
}


.stats_percentage_plus_minus {
  width: 11px !important;
  height: 9px !important;
  margin-right: 2px;
}


.top-downloaders-length-check{
  display: flex !important;
  flex-direction: column !important;
  max-height: 80vh !important;
  margin: auto !important;
}

.top-downloaders-length-check-1{
  display: flex !important;
  /* flex-direction: row !important; */
  flex-direction: column !important;

  max-height: 80vh !important;
  margin: auto !important;
}

.imgIcon-dm-ctrl {
  width: 60px !important;
  height: 60px !important;
  cursor: not-allowed !important;
}
@media screen and (min-width: 1700px) and (max-width: 1920px) {
  .tracks_image {
    width:100%
  }
  
  
}
@media screen and (min-width: 1700px) and (max-width: 1856px) {
  .video_image_tracks{
    width:345px
  }
  .image_border{
    width: 345px
  }
  
}


@media screen and (min-width: 1550px) and (max-width: 1700px) {
  .tracks_image {
    width:100%
  }
  .video_image_tracks{
    width:322px
  }
  .image_border{
    width: 322px
  }
  .edit_image{
    width:50px;
    height:50px
  }
  
}

@media screen and (min-width: 1550px) and (max-width: 1624px) {
  .video_image_tracks{
    width:300px
  }
  .image_border{
    width: 300px
  }
  
  
}
@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .statusbuttonsactive {
    width: 120px !important
  }
  .statusbuttons {
  
    width: 120px !important
  }
  .edit_image{
    width: 55px;
    height:55px
  }
  
}
@media screen and (min-width: 1460px) and (max-width: 1550px) {
  .video_image_tracks{
    width:280px
  }
  .image_border{
    width: 280px
  }
  .edit_image{
    width:45px;
    height:45px
  }
  
}
@media screen and (min-width: 1300px) and (max-width: 1460px) {
  .video_image_tracks{
    width:230px
  }
  .image_border{
    width: 230px
  }
  .edit_image{
    width:30px;
    height:30px
  }
  
}

@media screen and (min-width: 1300px) and (max-width: 1550px) {
  .tracks_image {
    width:100% !important
    
  }
 
  
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .tracks_image {
    width:100% !important
    
  }
  .video_image_tracks{
    width:400px
  }
  .tracks_songs_text{
    max-width: 170px;
  }
  .image_border{
    width: 400px
  }
  
}
@media screen and (max-width: 1024px) {
 
  .video_image_tracks{
    height: 193px;
    width: 100% !important;
  }
  .image_border{
    width: 100% 
  }
  .videos_card{
    width: 100% !important;
  }
}


@media screen and (max-width: 400px) {
  .edit_image {
    height: 50px !important;
    width:50px !important;
  }
  .video_image_tracks{
    height: 193px;
    width: 300px !important;
  }
  .tracks_songs_text{
    max-width: 120px;
  }
}
.cursor-pointer{
  cursor: pointer !important;
}

.cursor-notAllowed{
  cursor:not-allowed !important
}

.playlist_track_border{
  border-radius: 7px;
background: #141414;
}

.edit_playlist_uploader .ant-upload.ant-upload-select-picture-card > .ant-upload {
  height: 100% !important;
  width: 349px !important;
  border-radius: 12px !important;
  background-color: black !important;
  /* border: solid 3px #212121 !important; */
  position: relative !important;
}
