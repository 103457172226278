:root {
  --fontFamily: Inter !important;
 }
 
 
 .defaultDMcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 20px;
 }
 
 .showPrivateMessageList{
  color: white !important;
    padding: 7px 35px !important;
    font-family: var(--fontFamily) !important;
    font-size: 32px !important;
    font-weight: 900 !important;
    text-align: center !important;
}

 .deafultcaption {
  text-align: center;
  margin-top: -10px;
  color: white;
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: 600;
 }
 
 
 .defaultHeading {
  color: white;
  font-size: 24px;
  font-weight: bold;
 }
 
 
 
 
 .defaultButton {
  color: white;
  padding: 7px 35px;
  border-radius: 100px;
  background-color: #3959f9;
  cursor: pointer;
  font-family: var(--fontFamily) !important;
  font-size: 22px;
  font-weight: 900;
 }
 
 
 
 
 /* Comment Chat Dialog */
 .DMChatDialog .MuiPaper-root{
  background-color: black;
  justify-content: space-between;
 }
 .DMChatDialog .DMChatBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  width: 60%;
 }
 .DMChatDialog .DMChatBox .CommentChatInput{
  margin-bottom: 16px;
  width: 100%;
  background-color: black;
 }
 .DMChatDialog .DMChatBox .CommentChatContainer{
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 50px;
  width: 100%;
 }
 .DMChatDialog .DMChatBox .CommentChatContainer::-webkit-scrollbar {
  display: none;
 }
 /* Comment Chat Dialog END */
 
 
 
 
 @media screen and (max-width: 830px) {
  .DMChatDialog .DMChatBox {
    width: 80%;
  }
 } 