.profileImg {
  /* width: 120px !important;
  height: 120px !important; */
  width: 87px !important;
  height: 87px !important;
}

.upper-video-div-none {
  display: none;
}
.profileImg-default{
  width: 87px !important;
  height: 87px !important;
  border: 1px solid #212121 !important;
}

.profileImg-default> img {
  display: initial !important;
  width: 65% !important;
  height: 55% !important;
  object-fit: cover !important;
  margin-top: -9px !important;
}

.title {
  font-family: sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #f6f6f6 !important;
  margin-left: 8px !important;
  margin-top: 12px !important;
}

.listener-profile-play-grid {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.new-released-video {
  height: 100%;
  width: 100%;
  border-top-left-radius: 48%;
  border-bottom-left-radius: 48%;
  border: 8px solid black !important;
  object-fit: cover;
}

.listener_profile {

  width: 100%;
  height: 100%;

}

.listener-count-grid {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.25);
  margin-right: 2%;
  border-radius: 20px;
}

.listener-private {
  /* height: 90%; */

  margin: 2% auto !important;

}

.listener-public {
  height: 90%;
  margin: auto;
}

.subtitle-grid {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  color: white;
}

.buttongroup-grid {
  margin: 0px 0px 11px 0px !important;
  display: flex;
  justify-content: center;
}

.listener-follow-handle {
  height: 20% !important;
}

.compose-grid {
  display: flex;
  /* justify-content: center; */
}

.follow-button {
  background-color: #f00 !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  z-index: 2 !important;
  margin: 10px 17px !important;
  padding: 7px 22px !important;
}


.listener-follow-btn {
  background-color: #f00 !important;
  color: white !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  z-index: 2 !important;
  /* height: 40px !important; */
  /* width: 88px !important; */
  margin: 10px 17px !important;
  padding: 7px 44px;
}

.third-button {
  margin: 12px 23px 12px 0px !important;
  border: none !important;
  /* height: 40px !important; */
}

.one-button {
  margin: 12px 0px 12px 23px;
  border: none !important;
}

.btn-group-style {
  /* padding: 7px 39px 7px 43px !important; */
  border-radius: 20.5px !important;
  border: solid 3px #141414 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.buttongroup-grid .Mui-disabled {
  pointer-events: unset !important;
  cursor: not-allowed !important;
}

.disable-follow-button {
  color: rgba(216, 216, 216, 0.5) !important;
  background-color: #454545 !important;
  width: 88px !important;
  height: 40px !important;
  font-size: 12px !important;
  cursor: no-drop !important;
}

.active-third-button {
  background-color: #f00 !important;
  color: white !important;
  border-bottom-right-radius: 20.5px !important;
  border-top-right-radius: 20.5px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  z-index: 2 !important;
  height: 40px !important;
  width: 88px !important;
  margin-left: -2px !important;
}

.icons {
  width: 20px !important;
  height: 20px !important;
  color: #d8d8d8 !important;
}

.icons-img {
  width: 48px !important;
  height: 48px !important;
}

.listener-icons-img {
  width: 48px !important;
  height: 48px !important;
}

.icons-text {
  color: white;
  font-size: 14px !important;
  margin-top: 8px !important;
  padding-left: 8px !important;
}

.icons-group {
  /* margin: 10px 0px 40px 0px !important; */
  /* margin: 20px 0px 19px 0px !important; */
  justify-content: center;
}

.div-group {
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  gap: 3px;
}

.profile-top-grid {
  position: relative;
  height: 35vh !important;
}

.profile-top-grid_listener {
  position: relative;
  /* height: 35vh !important; */
}

@media screen and (min-width: 600px) {
  .profile-top-grid_listener {
    /* position: relative; */
    height: 35vh !important;
  }
}



.listener-profile-top-grid {
  position: relative;
  height: 32vh !important;
}

.profile-top-grid-audio {
  height: 27vh !important;
  position: relative !important;

}

.listener-profile-top-grid-audio {
  height: 32vh !important;
  position: relative !important;

}

.listener-profile-justify {
  justify-content: space-between;
  height: 100%;
}

.listener-header-height {
  background-color: transparent !important;
  z-index: 99;
  height: 15%;
}

.listener-tab-parent-height {
  height: 85% !important;
  overflow: hidden auto;
  background-color: black;
}

.listener-tab-parent-without-data {
  height: 85% !important;
  overflow: hidden hidden;
  background-color: black;
}

.listener-tab-parent-height-audio {
  /* height: 57%; */
  height: 85%;
  overflow: hidden auto;
  background-color: black;
}


/* Responsive Media Query */
/* @media screen and (max-width: 349px) {
  .profile-top-grid-audio{
    height: 36vh !important;
    position: relative !important;
  
  }
} */
@media screen and (min-width:601px) and (max-width:699px) {
  .listener-icons-img {
    width: 40px !important;
    height: 40px !important;
  }

  .buttongroup-grid {
    padding-left: 0px !important;
  }

  .div-group {
    padding-left: 0px !important;
  }
}

@media screen and (min-width:700px) and (max-width:780px) {
  .listener-icons-img {
    width: 40px !important;
    height: 40px !important;
  }

  .buttongroup-grid {
    margin: 20px 0px 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 22px !important;

  }
}

@media (max-width: 600px) {
  .buttongroup-grid {
    margin: 0px !important;
  }

  .listener-icons-img {
    width: 40px !important;
    height: 40px !important;
  }

  .icons-group {
    margin: 8px 0px 7px 0px !important;
  }

  .listener-profile-justify {
    justify-content: center
  }

  /* .listener-profile-top-grid{
    position: relative;
    height: 35vh !important;
  } */
  /* .subtitle-grid {
    flex-direction: row;
  } */

  .listener-private {
    padding: 18px 10px 18px 10px !important;
    height: auto !important;

  }

  .listener-public {
    padding: 18px 10px 18px 10px !important;
    height: auto !important;
  }


  .list-name {
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .justify-center {
    display: flex;
    justify-content: left;
  }

  .justify-center-compose {
    display: flex;
    justify-content: center;
  }

  .icons-img {
    width: 43px !important;
    height: 43px !important;
  }

  /* .profile-top-grid{
    height: 70%;
  } */
  .upper-video-div {
    height: 100% !important;
  }
}

@media (max-width: 600px) and (min-width: 415px) {
  .upperDiv {
    /* width: 68% !important; */
    height: 100% !important;
  }

  .upper-video-div {
    /* width: 54% !important; */
  }
}


@media (max-width: 600px) {
  /* .profile-top-grid{
    height: 45% !important;
  } */

  .profile-count-parent {
    /* padding:14px 17px !important */
    padding: 14px 0px 14px 17px !important
  }

  .upper-video-div {
    display: none !important;
  }

  .upperDiv {
    width: 100% !important;
    height: 100% !important;
  }

  .upperDiv-width100 {
    width: 100% !important;
    height: 100% !important;
  }


  .compose-grid {
    justify-content: center !important;
  }

  .self-profile {
    justify-content: center !important;

  }
}

@media (max-width: 1024px) and (min-width: 600px) {
  .icons-group {
    /* margin: 40px 0px 40px 0px !important;
    display: flex; */
    justify-content: space-around;
  }
}

@media (max-width: 780px) and (min-width: 600px) {
  .div-group {
    /* flex-direction: column; */
    /* justify-content: space-evenly; */
    display: flex;
    align-items: center;
    margin-top: 21px !important;
  }
}

@media (max-width: 780px) {
  .compose-grid {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .self-profile {
    display: flex;
    justify-content: left;
    /* padding-left: 16px; */
  }

  .icons-group {
    justify-content: center;
  }


}

@media (min-width: 556px) and (max-width: 701px) {
  .left-padding {
    /* margin-left: 9%; */
  }
}


@media (max-width: 420px) {
  .profileImg {
    width: 50px !important;
    height: 50px !important;
  }

  .listener-header-height {
    background-color: black !important;
    z-index: 99;
    height: 22%;
  }

  .listener-tab-parent-height {
    height: 78%;
    overflow: hidden auto;
    background-color: black !important;
  }

  .listener-tab-parent-height-audio {
    /* height: 57%; */
    height: 85%;

    overflow: hidden auto;
    background-color: black;
  }
}

@media (max-width: 600px) {

  .title {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden !important;
    white-space: nowrap;

  }

  .listener-header-height {
    background-color: black !important;
    z-index: 99;
    height: 22%;
  }

  .listener-tab-parent-height {
    height: 78% !important;
    overflow: hidden auto;
    background-color: black !important;
  }

  .listener-tab-parent-height-audio {
    /* height: 57%; */
    height: 78%;
    overflow: hidden auto;
    background-color: black;
  }
}