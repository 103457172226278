.button_dashboard{
    /* border-bottom-right-radius: 20.5px !important;
    border-top-right-radius: 20.5px !important; */
    color: #ffffff !important;
   /* background-color: #171717 !important; */
    background-color:transparent !important;
    width: 50px !important;
    cursor: pointer !important;
    height: 35px !important;
    margin-left: 14px !important;
    margin-top: 5px;
    border-radius: 35px !important;
}


.button_dashboard:disabled {
    background-color: #274af4 !important;
    cursor: not-allowed !important;
  }

  .dashboard_audio_button{
    background-color: transparent  !important;
    width: 50px !important;
    border: none !important;
    /* margin-left: 14px !important; */
  }
  .dashboard_video_button{
    margin-left: 14px !important;
    background-color: transparent !important;
    border-radius: 35px !important;
    width: 50px !important;
    border: none !important;
  }

  .buttonBoxWrapper{
    background-color:#212121;
    height: 44px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-Content: center;
    padding-left: 11px;
  }

  @media screen and (max-width: 700px) {
    .wallettop_container {
      display: flex !important;
      gap: 360px !important;
      flex-direction: column !important;
    }
    .wallet_container_wrapper{
      padding: 0px 4px 0px 14px;
    }
  }
 @media screen and (max-width: 400px) {
    .dashboard_audio_button{
        width: 33px !important;
    }
    .dashboard_video_button{
        width: 33px !important;
    }
    .button_dashboard{
        width: 33px !important;
    }
    
    .topheader_buttons{
        width: 48% !important;
    }
    .buttonfilterbox{
        justify-content: unset !important;
        gap: 10% !important;
        
    }
  }