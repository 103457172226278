.user-profile{
    width: 100%;
     height: auto;
}

.main-grid{
    display: 'flex';
    flex-direction: row;
}

.main-name-grid{
    display: flex;
    flex-direction: row;

}

.search-users-scroll::-webkit-scrollbar {
    width: 15px;
  }
  
  /* Track */
  .search-users-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  .search-users-scroll::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }

  .user_icon_style{
    width: 100px !important;
    height: 100px !important;
    border: 3px solid #fff;
  }

.search-users-scroll {
    width: 100%;
    /* height: 650px; */
    /* min-height: 60px; */
    /* max-height: 700px; */
    /* background-color: #fff; */
    /* border: 1px solid red; */
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
   }

.user-name-size{
    font-weight: bold;
    font-size: 20px;
    color:white;
}

.follow-butt{
    margin-left: 50px;
}

@media  screen and (max-width:410px) {
    .user-profile{
    width: 100px;
    }
    .main-grid{
        flex-direction: column;
    }
    /* .main-name-grid{
        display: flex;
        flex-direction: column;
    
    } */
    .icons-grid{
       margin-left: -14px;
    }

    .user-name-size{
        font-size: 10px;
    }
    
}


.play-icons-margin-left{
    margin-left: -10px;
}


@media  screen and (min-width: 1000px) and (max-width: 1100px) {
    .play-icons-margin-left{
        margin-left: -10px;
    }

    .follow-icon-left{
      margin-left: 10px;
    }

    .main-name-grid{
        display: flex;
        flex-direction: column;
    
    }

    .user-profile{
        width: 100%;
        height: 90px;
    }
}

@media  screen and (min-width:410px) {
    .user-name-size{
        font-size: 12px;
    }
}

.follow-button-width{
    width: 100%;
}

@media screen and (max-width: 430px) {
    .follow-button-width{
        width: 35%;
    }
}

@media screen and (min-width: 431px) and (max-width: 599px) {
    .follow-button-width{
        width: 50%;
    }
}

@media  screen and (min-width:600px) {
    .user-name-size{
        font-size: 14px;
    }
}

@media  screen and (min-width:768px) {
    .user-name-size{
        font-size: 16px;
    }
}

@media  screen and (min-width:992px) {
    .user-name-size{
        font-size: 18px;
    }
}

@media  screen and (min-width:1200px) {
    .follow-butt{
        margin-left: 40px;
    }
    .user-name-size{
        font-size: 20px;
    }
}

@media screen and (min-width: 1101px) {
    .user-profile{
        width: 85%;
        height: 100px;
    }
}

@media screen and (min-width: 600px) and (max-width: 950px){
    .user-profile{
        width: 85%;
        height: 100px;
    }
}

.display-user-icons {
    display: flex;
    margin-top: 5px;
}
@media screen and (max-width: 1274px) {
    
  
    .display-user-icons {
      justify-content: end;
      margin-top: 10px;

    }
  }

  @media screen and (max-width: 600px) {
    .hide_in_mobile_search_users{
      display: none !important;
    }
  }

  @media screen and (min-width: 601px) {
    .hide_in_medium_and_large_search_users{
      display: none !important;
    }
  }


  .search-users-scroll::-webkit-scrollbar {
    width: 15px;
  }

  
  .search-users-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .search-users-scroll::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }