:root {
    --font-family: Inter;
}

.MainLayoutContainer {
    background-color: black;
    overflow: hidden;
}

.MainContent {
    margin: 0px;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.UserTypeDealDetails {
    display: flex;
    justify-content: space-around;
    align-content: center;
    height: 111px;
    width: 208px;
}

.UserTypeDealDetails .Avatar {
    width: 50px;
    height: 50px;
}

.UserTypeDealDetails .SplitPercentageText {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
    color: white;
    background: #212121;
    border-radius: 30px;
}

.UserTypeDealDetails .SplitDurationBox {
    /* position: absolute; */
    bottom: 0px;
    margin-bottom: 28px;
}

.MainLineBox .SplitDurationBox .PVCommonText.DurationText {
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.UserTypeDealDetails .MainLineBox {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MainLineBox .ChildBox {
    width: 100%;
    margin-bottom: 15px;
}

.MainLineBox .MainLine {
    background: white;
    min-width: 109px;
    height: 3.5px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.MainLineBox .MainLine .Avatar {
    width: 21.5px;
    height: 21.5px;
}



.DealContent {
    overflow-y: auto;
    padding-bottom: 48px;
    overflow-x: hidden;
}
.DealContent::-webkit-scrollbar-thumb {
    background-color: red!important;
  }
  
.DealContent::-webkit-scrollbar-track {
  background: #191919 !important;
  border-radius: 10px !important
}

.DealContent::-webkit-scrollbar{
   width: 10px;
   height: 0px !important;
}
  
  

.DealHeaderContainer {
    width: 100%;
    height: 300px;
    position: relative;
    flex-direction: column;
    padding: 64px 48px;
    justify-content: flex-end;
    margin-top:16px;
}

.DealHeaderContainer .Title {
    width: 100%;
    font-size: 48px;
    font-family: "Inter", sans-serif;
    color: white;
    line-height: 1.1;
    white-space: nowrap;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.24px;
}

/* Deal Type Start */
.DealContent .DealTypeTitle {
    color: white;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    padding: 24px 48px;
}

.DealTypeCardGrid {
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    place-content: center;
}

.DealTypeCardGrid::-webkit-scrollbar {
    display: none;
}

.DealTypeCard {
    min-width: 300px;
    width: 85%;
    min-height: 520px;
    border: 5px solid white;
    border-radius: 30px;
    max-width: 360px;
    max-height: 100%;
     margin-top: 10px !important;
    margin: 0px 16px;
}

.DealTypeCard .TextDiv {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0px 32px;
}

.TextDiv .Title {
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
}


.TextDiv .SplitText {
    color: #3959f9;
    font-size: 55px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
}


.TextDiv .Description {
    color: white;
    font-size: 22px;
    line-height: 1.5;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
}

.MediaDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 24px 0px 32px;
}

.MediaDiv .Image {
    height: 128px;
    width: 128px;
    object-fit: cover;
    border-radius: 12px;
}

.MediaDiv.Relative {
    position: relative;
}

.MediaDiv .VideoImage {
    height: 128px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.MediaDiv .VideoPlayIcon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
}

.MediaDiv .UserImage {
    height: 68px;
    width: 68px;
    object-fit: contain;
}

.MediaDiv .UserText {
    color: white;
    background: #212121;
    padding: 4px 16px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    border-radius: 20px;
}

.UserSplitLineBox .LineBox {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LineBox .Line {
    background: white;
    min-width: 112px;
    height: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.LineBox .Line .Avatar {
    width: 26.5px;
    height: 26.5px;
}

.LineBox .ChildBox {
    width: 100%;
    margin-bottom: 32px;
}

.LineBox .SplitDurationBox {
    position: absolute;
    bottom: 0px;
    margin-bottom: 28px;
}

.SplitDurationBox .DurationText{
    font-family: "Inter", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.MediaDiv.UserSplitLineBox {
    justify-content: space-around;
    height: 152px;
}

.OfferButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px;
}

.OfferButtonDiv .Button {
    background-color: red;
    color: white;
    border-radius: 32px;
    padding: 16px 48px;
}

.OfferButtonDiv .Button span {
    color: white;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
    text-transform: none;
}

/* Deal Type END */

/* Trending List START*/
.TLContainer {
    margin-top: 32px;
}

.TLTitleGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TLTitleGrid .Title {
    color: white;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    padding: 24px 48px;
}

.TLTitleGrid .RightText {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
    text-transform: none;
    padding: 24px 48px;
}

.TLListGrid {
    display: flex;
    align-items: center;
}

.TLListGrid .IndexNumber {
    color: white;
    margin: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
}

.TLListGrid .Image {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin: 8px;
}

.TLListGrid .Image .MuiAvatar-img {
    object-fit: contain;
}

.TLListGrid .detailBox {
    width: 100%;
}

.TLListGrid .FlexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TLListGrid .Title {
    color: white;
    margin: 4px;
    display: flex;
    align-items: center;
    font-weight: bold;
    flex-grow: 0;
    font-family: "Inter", sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.TLListGrid .FS12 {
    font-size: 12px;
}

.TLListGrid .FS14 {
    font-size: 14px;
}

.TLListGrid .FS16 {
    font-size: 16px;
}

.Title.MR48 {
    margin-right: 48px;
}

.TLListGrid .CheckIcon {    
    background-color: #3959f9;
    border-radius: 100px;
    height: 16px;
    width: 16px;
    margin-left: 4px;
}


.TLListGrid .SubTitle {
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    margin: 4px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}

.SubTitle.MR48 {
    margin-right: 48px;
}

.TLListGrid .ProgressBox {
    margin: 4px 48px 4px 4px;
}

/* Trending List END*/

@media only screen and (max-width:1280px) {
    

    .MediaDiv .HorizonalLine {
        min-width: 78px;
    }
}

@media only screen and (max-width: 599px) {
    .DealHeaderContainer {
        padding: 64px 32px;
    }

    .DealHeaderContainer .Title {
        font-size: 36px;
    }
   
      .DealTypeCard{
        margin-top: 18px;
      }

    .DealContent .DealTypeTitle {
        padding: 24px;
        font-size: 18px;
    }

    .TLTitleGrid .Title {
        padding: 24px 4px 24px 24px;
        font-size: 18px;
        white-space: nowrap;
    }

    .TLTitleGrid .RightText {
        padding: 24px 4px;
        font-size: 14px;
        white-space: nowrap;
    }

    .SubTitle.MR48 {
        margin-right: 0px;
    }

    .Title.MR48 {
        margin-right: 0px;
    }

    .TLListGrid .ProgressBox {
        margin: 4px 0px 4px 4px;
    }

    .TLListGrid .IndexNumber {
        margin-left: 8px;
        margin-right: 4px;
    }

    .TLListGrid .Image {
        margin: 4px;
    }

    .TLListGrid .detailBox {
        padding: 0px 8px;
    }

    .MediaDiv .HorizonalLine {
        min-width: 78px;
    }
} 