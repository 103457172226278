:root {
    --fontFamilyPoppins: Poppins !important;
  }

  
.SingleSongCard-body{
    height: 60px;
    width: 100%;
   
    padding: 5px 8px 4px;
    color: #fff;
    display: grid;
    align-items: center;
    grid-template-columns: 0.5fr 1fr 2fr 4fr 2fr 3fr 3fr 0.5fr;
    
    gap: 10px;
    
    background-color: #121212;
}

.single-card-playlist{
    padding: 6px 0px 4px 0px !important;
    display: flex !important;
    align-items: center !important;
}

.dflex{
    display: flex !important;
}

.position-playlist-relative{
    position: relative !important;
}

.playlist-lock-icon{
    width: 17px !important;
    height: 17px !important;
    position: absolute !important;
    top: 30% !important;
    left: 29% !important;
}

.allplaylist-audio-img{
    border-radius: 8px !important;
    width: 50px !important;
    height: 50px !important;
    opacity: 0.5 !important;
}
#song-active{
    padding: 2px;
    border-radius: 8px;
    border: solid 1px #525252;
    background-color: #191919;
}
.song-number{
    font-family: var(--fontFamilyPoppins) !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.28px;
    text-align: center;
    color: #fff;
}
.song-cover img{
    margin-top: auto;
    margin-bottom: auto;
    height: 54px;
    width: 54px;
}

.song-desc h3{
 margin-bottom: 0;
 color: #efefef;
 width: 165px;
 overflow: hidden;
 display: inline-block;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.song-desc p{
    margin-bottom: 0;
    color: #9f9f9f;
    width: 107px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.song-play {
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
}
.song-play #playButton{
    height: 30px;
    width: 30px;
    margin-right: 14px ;
}
.song-play #banner {
    height: 80%;
    width: 100%;
}

.song-comments{
    display: flex;
    /* gap: 10px;
     */
     margin-right: 16px;
    align-items: center;
}
.song-comments div{
    display: flex;
    gap: 5px;
    align-items: center;
}
.song-comments img{
    height: 20px;
    width: 20px;
}
.song-comments p{
    margin-bottom: 0;
}

.song-percent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}
.song-percent p{
    margin-bottom: 0;
    font-size: 12px;
}


  
  .selected {
    /* border: 1px solid blue !important; */
    border-radius: 7px;
    border: 1px solid rgb(102, 102, 102);
  }

  /* @media screen and (max-width: 576px) { */
  @media screen and (max-width: 600px) {

    .single-card-playlist {
      align-items: flex-start !important;
    }

}