:root {
  --fontFamily: Inter !important;
}
.Music-Royalties {

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  color: #ffff;
  font-family: var(--fontFamily) !important;
}

.wallet_container_wrapper {
  padding: 0 52px 0 31px
}
.balance_text{
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.12px;
  text-align: left;
  color: #ffffff;
}

.This-Week {

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: var(--fontFamily) !important;
}
.plus{
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color:#3959f9;
}

.singer_icon {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.received {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #3959f9;

}

.Swalla {
  width: 40px;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  white-space: nowrap;
  color: #ffffff;
  font-family: var(--fontFamily) !important;
}

.Jason-Derulo {
  width: 78px;
  flex-grow: 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--fontFamily) !important;
}

.money {
  /* width: 65px; */
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  white-space: nowrap;
  font-family: var(--fontFamily) !important;
}

.text-style-1 {
  width: 47px;
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  font-family: var(--fontFamily) !important;
}


.wallet_leftside_container {
  margin-top: 12px;
  height: 54px;
  padding: 0px 14px 0px 5px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.wallet_rightside_container_box {
  width: 476px;
  height: 732px;
  border-radius: 30px;
  border: solid 5px #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #ff0010 0%, #000000 68%)
}
.singer_title{
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  
  color: #ffffff;
  white-space: nowrap
}
.shareWrapper{
  display: flex;
flex-direction: column;
gap: 20%;
}

.shareButton {
  width: 46px;
  height: 46px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 100px;
  border: solid 1px #ffff;
  background-color: rgba(33, 33, 33, 0.5);
  margin-bottom: 10px;
}
.wallet_leftside_box_wrapper::-webkit-scrollbar-thumb {
  background-color: red!important;
}
.wallet_leftside_box_wrapper::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important
}
.wallet_leftside_box_wrapper::-webkit-scrollbar{
  width: 10px;
  height: 0px !important;
}

.buttonfilterbox {
  justify-content: flex-end;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 1%
}

.Send {
  width: 36px;
  height: 18px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffff;

}

.wallettop_container {
  gap: 3%;
  display: flex;
  margin-bottom: 10px;
}

.rightsidecontainer {
  align-items: center;
  width: 50%;
  display: flex;
  justify-content: end
}

.wallet_leftside_box_wrapper {
  overflow-x: hidden !important;
  height: 693px;
  margin-top: 10px
}

.wallet_leftside_ownerstats {
  gap: 10px;
  display: flex;
  flex-direction: column
}
.topdealers_leftside_ownerstats {
  gap: 4px;
  display: flex;
  flex-direction: column
}

.wallet_wraper {
  margin-top: 8%;
  text-align: center;
  display: flex;
  gap: 3%
}

.revenue_right_box_wrapper {
  height: 80%;
  background: black;
  display: flex;
  width: 80%;
  align-items: center
}

.wallet_leftside_element {
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
}

.wallet_container_element {
  height: 50vh;
  width: 50%
}

@media screen and (max-width: 700px) {
  .wallettop_container {
    display: flex !important;
    gap: 360px !important;
    flex-direction: column !important;
  }
  .wallet_container_wrapper{
    padding: 0px 4px 0px 14px;
  }

  .wallet_container_element {
    width: 100% !important;
    height: 51vh !important
  }

  .musicdeal_right_side {
    width: 100% !important;

  }

  .rightsidecontainer {
    width: 100% !important;
    justify-content: center;
  }

  .audio_wallet_button_group {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .buttonfilterbox{
    align-items: unset;
  }

  .iaiaiaiai {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
 
  .buttonfilterbox{
    align-items: unset;
  }

  
}