:root {
  --fontFamily: Inter !important;
}

.custom_sidebar {
  height: 900px;
  margin: 0 6px 225px 0;
  padding: 27px 0 0;

  background-color: #090909;
}

.cm-menu {
  display: flex;
  flex-direction: column;
  /* removing this unnecessary height */
  /* height: 585px; */
  justify-content: space-between;
}

.help-menu {
  background-color: bisque;
}

.seleted_text {
  /* width: 80px; */
  /* height: 30px; */
  margin-left: 12px;
  font-size: 17px;
  font-weight: 900;
  color: #3959f9;
  font-family: var(--fontFamily) !important;
}

.non_seleted_text {
  /* width: 80px; */
  margin-left: 12px;
  /* height: 30px; */
  font-size: 17px;
  font-weight: 900;
  color: #ffffff;
  font-family: var(--fontFamily) !important;
}

/* root element for ant */
#components-layout-demo-responsive .logo {
  height: 54px;
  margin: 12px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-menu-title-content {
  display: flex;
  align-items: center !important;
}
.custom_menu
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: transparent !important;
  width: 100% !important;
  /* height: 54px !important; */

  /* border-radius: 27px 0px 0px 27px !important; */
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  transition: 0;
}

@media screen and (max-width: 5464px) {
  .mainHeight {
    zoom: 350%;
  }
}

@media screen and (max-width: 4098px) {
  .mainHeight {
    zoom: 275%;
  }
}

@media screen and (max-width: 2732px) {
  .mainHeight {
    zoom: 175%;
  }
}

@media screen and (max-width: 2050px) {
  .mainHeight {
    zoom: 125%;
  }
}

@media screen and (max-width: 1700px) {
  .mainHeight {
    zoom: 100%;
  }
}
