:root {
  --fontFmaily: Inter;
}

.active_myplan_tab {
  text-transform: capitalize !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-shadow: currentcolor 0px 0px 0.25px !important;
  border-bottom: 1px solid !important;
}

.non_active_myplan_tab {
  text-transform: capitalize !important;
  color: #8f92a1 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-shadow: currentcolor 0px 0px 0.25px !important;
  border-bottom: 1px solid !important;
}

.m_myplan_font {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  font-family: var(--fontFmaily);
}

.m_custom_tab_font {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1px;
  color: #696c77;
}

.myplantab_content {
  padding: 30px;
}

.m_creativePlan {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.m_redColor {
  color: red;
  stroke-width: 3px;
  stroke: red;
}

/* user current plan */

.m_plan_name_div {
  border-radius: 10px;
  border: solid 3px #212121;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 3px; */
  padding: 0px 12px;
  margin: 5px 0px;
}

.m_user_plan_div {
  padding: 5px 70px;
}

.m_user_plan_continer {
  border: 6px solid #212121;
  background: #000000;
  border-radius: 20px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
  padding: 10px 80px;
}

.m_currentPlan_text {
  font-size: 16px;
  font-weight: 900;
  /* padding: 5px 5px; */
  color: #3959f9;
}

.m_plan_name {
  border-radius: 10px;
  /* border: solid 3px #212121; */
  /* padding: 3px; */
  padding: 0px 5px;
  color: white;
  font-size: 30px !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  display: flex;
  align-items: center;
}

.m_subscriber_content {
  display: flex;
  flex-direction: column;
  padding: 15px 0px 0px 8px;
  line-height: 1;
}

.m_subscriber_title {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-family: var(--fontFmaily);
}

.m_subscriber_plan_rupees {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: var(--fontFmaily);
}

.m_cancel_button {
  margin-top: 20px;
}

.m_cancelPlan_button {
  height: 46px;
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 60px;
  background-color: #212121;
  border: none;
  width: 100%;
  cursor: pointer;
  margin: 30px 0px 5px 0px;
}

@media screen and (max-width: 600px) {
  .m_user_plan_continer {
    padding: 10px 50px;
  }
}

@media screen and (max-width: 550px) {
  .m_user_plan_div {
    padding: 5px 60px;
  }
}

@media screen and (max-width: 530px) {
  .m_user_plan_continer {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 520px) {
  .m_user_plan_div {
    padding: 5px 55px;
  }
}

@media screen and (max-width: 510px) {
  .m_user_plan_continer {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 500px) {
  .m_user_plan_div {
    padding: 5px 50px;
  }
}

@media screen and (max-width: 480px) {
  .m_user_plan_div {
    padding: 5px 45px;
  }
}

@media screen and (max-width: 460px) {
  .m_user_plan_div {
    padding: 5px 40px;
  }
}

@media screen and (max-width: 440px) {
  .m_user_plan_div {
    padding: 5px 35px;
  }
}

@media screen and (max-width: 430px) {
  .m_user_plan_continer {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 420px) {
  .m_user_plan_div {
    padding: 5px 30px;
  }
}

@media screen and (max-width: 410px) {
  .m_user_plan_continer {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 400px) {
  .m_user_plan_div {
    padding: 5px 25px;
  }
}

/* @media screen and (max-width: 390px) {
  .m_user_plan_continer {
    padding: 10px 30px;
  }

} */

@media screen and (max-width: 380px) {
  .m_user_plan_div {
    padding: 5px 20px;
  }
}

@media screen and (max-width: 370px) {
  .m_user_plan_continer {
    padding: 10px;
  }
}

@media screen and (max-width: 360px) {
  .m_user_plan_div {
    padding: 5px 15px !important;
  }
  .m_plan_name {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 320px) {
  .m_user_plan_div {
    padding: 5px 0px;
  }
  .m_plan_name {
    font-size: 22px !important;
  }
}

.m_creative_get_btn {
  border-radius: 30px;
  font-family: var(--fontFmaily);
  font-size: 22px;
  font-weight: 900;
  background-color: #fff;
  color: black;
  width: 172px;
  height: 45px;
  /* padding: 10px; */
  border: none;
  cursor: pointer;
  margin: 5px 0px;
}

.m_creative_your_btn {
  /* margin: 10px 0px; */
  border-radius: 30px;
  font-family: var(--fontFmaily);
  font-size: 22px;
  font-weight: 900;
  background-color: #0f6fd1;
  color: #fff;
  width: 172px;
  height: 45px;
  /* padding: 10px; */
  border: none;
  /* cursor: pointer; */
  margin: 5px 0px;
}

.m_creativePlanPriceFont {
  color: #ffffff;
  /* font-size: 90px; */
  font-weight: 900;
  line-height: 1;
  margin-bottom: 5px;
  font-family: var(--fontFmaily);
  font-size: 60px;
}

/* my card  */
.m_update_btn {
  border-radius: 60px;
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  width: 248px;
  border: none;
  height: 47px;
  background-color: #212121;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.m_payment_Card {
  border: 6px solid #212121;
  background-color: #000000 !important;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin: 5px; */
  /* margin: 0px 25px; */
  padding: 10px;
  padding-left: 20px;
  height: 175px;
  /* height: 225px; */
  /* width: 300px; */
}

.m_cardType {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m_cardFont {
  /* font-weight: 800; */
  color: #ffffff;
  font-family: var(--fontFmaily);
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.m_cardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* line-height: 0.8; */
}

/* update Card */
.m_paper_back {
  /* width: 75%; */
  /* height: 80%; */
  display: flex;
  flex-direction: column;
  background: black url(/static/media/mobileBackground.6af660cc.png) no-repeat
    center center;
  background-size: 800vh;
}

.m_plan_update {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* justify-content: end; */
  /* height: 100%; */
  padding: 20px;
}

.m_plan_update_btn {
  /*width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 36%;
  /* padding: 20px; */
  border-radius: 30px 30px 0px 0px;
  border: 5px solid rgb(33, 33, 33);
  background-color: #000000;
}

.m_plan_update_font {
  font-family: var(--fontFmaily);
  font-size: 32px;
  font-weight: 900;
  color: #fff;
  text-align: center;
}

.m_plan_btn {
  width: 300px;
  height: 50px;
  border-radius: 30px;

  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  /* background-color: #3959f9; */
  cursor: pointer;
  border: none;
}

.m_plan_description {
  padding: 8px 82px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.m_fontWeight {
  display: inline-block;
  width: 250px;
  /* border: 2px solid red; */
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

@media screen and (max-width: 380px) {
  .m_plan_btn {
    width: 250px;
  }
}

@media screen and (max-width: 340px) {
  .m_plan_btn {
    width: 200px;
  }

  .m_fontWeight {
    width: 180px;
  }
}

/* save card details */

.m_new_card {
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
}

.m_details {
  font-family: var(--fontFmaily);
  font-size: 40px;
  font-weight: 900;
  color: #ffffff;
}

/* .m_save_card {
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  background-color: red;
  border: none;
  width: 75%;
  border-radius: 60px;
  height: 56px;
} */

.m_pay_button {
  /* width: 300px; */
  height: 55px;
  border-radius: 60px;
  border: none;
  font-size: var(--fontSize);
  font-family: var(--fontFmaily);
  font-weight: 900;
  color: #ffffff;
  background-color: #ff0000;
  cursor: pointer;
}

.m_keepmyplan_button {
  height: 55px;
  border-radius: 30px;
  background-color: #212121;
  font-family: var(--fontFmaily);
  font-size: var(--fontSize);
  font-weight: 900;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
}

.index-loader-style {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80vh !important;
  width: 100% !important;
}

.confirm_active_myplan_tab {
  text-transform: capitalize !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-shadow: currentcolor 0px 0px 0.25px !important;
  border-bottom: 1px solid !important;
}

.confirm_non_active_myplan_tab {
  text-transform: capitalize !important;
  color: #8f92a1 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-shadow: currentcolor 0px 0px 0.25px !important;
  border-bottom: 1px solid !important;
}

.currentplan-align {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}
