.MainLayoutContainer {
    background-color: black;
    overflow: hidden;
}

.MainContent {
    margin: 0px;
    background-color: black;
    display: flex;
    flex-direction: column;
}


.MainContent .CommonText {
    font-family: "Inter", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.MainContent .InfoTextSm {
    font-size: 20px;
    font-weight: 900;
    line-height: normal;
    color: white;
}


.AudioSplitContent {
    overflow-y: auto;
}

.AudioSplitContent::-webkit-scrollbar-thumb {
    background-color: red!important;
  }
  
.AudioSplitContent::-webkit-scrollbar-track {
  background: #191919 !important;
  border-radius: 10px !important
}

.AudioSplitContent::-webkit-scrollbar{
   width: 10px;
   height: 0px !important;
}

.MainContentDiv {
    display: flex;
    width: 100%;
}

.AudioSplitContent .ColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.AudioSplitContent .AICenter {
    align-items: center;
}

.MT24{
    margin-top: 24px;
}

@media screen and (max-width: 1079px) {
    .MainContentDiv {
        flex-direction: column;
        align-items: center;
    }

    .AudioSplitContent {
        margin-top: 48px;
    }

}

@media screen and (max-width: 599px) {

    .AudioSplitContent {
        margin-top: 32px;
    }
}