.main-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
 /* fixing height  */
  height: 10vh !important;
  align-items: center;

}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .header_no_flex_wrap{
    flex-flow: nowrap !important;
  }
}

.menu-icon {
  height: 30px;
  width: 90px;
  color: white;
}

.border-line{
  border: solid 3px #212121;
  padding: 1%;
  border-radius: 65px;
  margin: 8px 0px;

}

.menu-icon .MuiSvgIcon-root {
  width: 2rem;
  height: 1.5rem;
}

.listener_username {
  display: inline-block;
  text-overflow: ellipsis;
  /* width: 167px;
  max-width: 167px;
  min-width: 167px; */
  min-width: 98%;
  max-width: 98%;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  /* display: contents; */
}
.username_ellipse {
  display: inline-block;
  text-overflow: ellipsis;
  
  /* min-width: 128px;
  max-width: 128px; */
  min-width: 98%;
  max-width: 98%;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  font-family: karla !important;
  /* display: contents; */
}

/* .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 25px;
}
.ant-select-arrow {
  color: rgb(143, 146, 161);
} */

.search_input .ant-input-affix-wrapper {
  /* border-radius: 20px; */
  border-radius: 24px;
  background-color: rgb(40, 40, 40);
  border: 2px solid rgb(40, 40, 40);
  padding: 8px 11px !important;
}
.search_input .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.search_input
 .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none !important;
}
.search_input .ant-input-affix-wrapper>input.ant-input {
  background-color: rgb(40, 40, 40) !important;
  color: white !important;
}

.search_location .ant-input-affix-wrapper>input.ant-input {
  background-color: rgb(29 28 28) !important;
  color: white !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border: none !important;
}
/* .left_part {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* width: 90%; */
/* height: 47px;
  margin: 20px 20px 25px 35px;
  padding: 10px 9px 9px 23px;
  border: 2px solid rgb(40, 40, 40);
  border-radius: 24px;
  background-color: rgb(40, 40, 40);
} */

/* .search_input {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  color: #8f92a1;

  border: none;
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
  /* width: 80%; */

/* height: 35px;
  background-color: rgb(40, 40, 40);
} */

.upload-justify {
  justify-content: space-around;
  align-items: center;
}

.search_track {
  /* width: 107px; */
  border-radius: 28px;
  background-color: rgb(9, 9, 9);

  height: 30px;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1px;
  color: #8f92a1;
}
.header_justify {
  justify-content: end;
}
.search_track
 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-bottom: 0px !important;
}

.search_icon {
  font-size: 25px;
}

.search_icon .ant-input-prefix {
  margin-right: 4px;
}

.upload_button_header {
  background-color: red;
  border-radius: 25px;
  width: 100%;
  /* height: 45px; */
  text-align: center;
}

/* .right_part {
  margin: 20px 30px 20px 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
} */
.right_part avatar {
  border: 1px solid red !important;
}

.right_part .login_btn {
  width: 111px;
  height: 34px;
  /* margin: 27px 14px 25px 178.7px;
  padding: 7px 30px 7px 31px; */
  border-radius: 23.5px;
  background-color: #ff0501;
  /* border-radius: 30px; */
  /* background-color: rgb(255, 5, 1); */
  border: none;
  /* width: 100px; */
  /* height: 40px; */

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: -0.28px; */
  text-align: center;
  color: #fff;
}

.right_part button {
  /* width: 111px; */
  height: 34px;
  /* margin: 27px 14px 25px 178.7px;
  padding: 7px 30px 7px 31px; */
  border-radius: 23.5px;
  background-color: #ff0501;
  /* border-radius: 30px; */
  /* background-color: rgb(255, 5, 1); */
  border: none;
  cursor: pointer;
  font-family: Poppins;
  /* width: 100px; */
  /* height: 40px; */

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: -0.28px; */
  text-align: center;
  color: #fff;
}

.right_part_loggedOut button {
  width: 111px;
  height: 34px;
  border-radius: 23.5px;
  background-color: #ff0501;
  border: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #fff;
}
.user_avatar_img {
  height: 47px !important;
  width: 47px !important;
}

.user_avatar_dm {
  height: 38px;
  width: 38px;
  /* border-radius: 50px; */
  border: solid 2px #212121;
  padding: 4px;
}
.default-profile-icon-header{
  border-radius: 50px !important;
  border: solid 2px #212121;

}
.default-profile-icon-header>img {
  display: initial !important;
  width: 65% !important;
  height: 55% !important;
  object-fit: cover !important;
  margin-top: -4px !important;
  border-radius: 0px !important;
}

.user_avatar .ant-avatar-image {
  height: 34px !important;
  width: 34px !important;
  /* height: 47px !important;
  width: 47px !important; */
}
.user_avatar .anticon svg {
  height: 47px !important;
}
.ant-btn:hover,
.ant-btn:focus {
  /* background-color:rgb(255, 5, 1); */
  color: #fff;
}

.custom-hovor-effect:hover,
.custom-hovor-effect:focus {
  background-color: #ff0501;
  color: #fff;
}
.custom_dropdown_profile {
  /* width: 100px; */

  width: fit-content;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.82px;
  color: #fff;
}

.custom_dropdown_profile:hover {
  color: #fff;
}

.custom-drop-menu {
  /* margin-top: 25px; */
  margin-top: 7px;
  border: 3px solid #212121 !important;
  background-color: black !important;
  font-weight: bold;
  color: white !important;
  border-top: none !important;
  border-radius: 0px 0px 18px 18px;
  /* left: 18px !important; */
  left: 0px !important;

}

.ant-dropdown-menu-item {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #fff;
  margin: 0px 25px 8px 25px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -1px;
  font-family: karla !important;
}
.ant-dropdown-menu-item:hover {
  background-color: inherit;
}

.header-dropdown-row{
  width: 87%;
  display: flex;
  justify-content: space-around;
  padding: 0px 3px;

}


@media screen and (max-width: 1550px) {
  .custom-drop-menu {
    /* left: 0px; */
    left: 18px;
  }
}

@media screen and (max-width: 576px) {
  .search_input {
    display: none !important;
  }

  .mobile_view_menu{   
     /* left: 64px !important; */
     left: 0px !important;
    top: 16px !important;
  }

  .search_track {
    display: none !important;
}
  .justify_content_start {
    justify-content: center !important;
  }
}

@media screen and (min-width: 577px) {

  .search-icon {
    display: none !important;
  }
}

@media screen and (max-width: 410px) {
  .upload_button {
    width: 91px !important;
  }

  .search-icon {
    margin-left: 10px;
  }
  .menu-icon {
    width: '25px';
  }
  .upload-justify {
    justify-content: space-evenly;
  }
  .search_icon {
    display: none !important;
  }

  .search_icon .ant-input-prefix {
    margin-right: 0px;
  }
}

@media screen and (min-width: 850px) and (max-width: 1062px) {
  .username_ellipse {
    display: inline-block;
    text-overflow: ellipsis;
    /* width: 88px;
    min-width: 88px;
    max-width: 88px; */
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    /* display: contents; */
  }
  .ant-dropdown-menu-item {

    margin: 0px 0px 8px 0px !important;
  }

 
  .header_justify {
    justify-content: center;
  }
}

@media screen and (max-width: 1062px) {
  .listener_username {
    display: inline-block;
    text-overflow: ellipsis;
    /* width: 165px;
    max-width: 165px;
    min-width: 165px; */
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    /* display: contents; */
  }
}


@media screen and (max-width: 335px) {
  .right_part button {
    font-size: 12px;
    /* width: 87px; */
    height: 30px;
  }
  .right_part_loggedOut button {
    font-size: 12px;
    width: 87px;
    height: 30px;
  }
  .sm_fontsize {
    font-size: 12px !important;
  }

}
.hide_search_bar_in_comments {
  display: none !important;
}
@media screen and (max-width: 600px) {

.header-dropdown-row{
  width: auto !important;

}}