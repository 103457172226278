.MainLayoutContainerSP {
    background-color: black;
    overflow: hidden;
}

.MainContentSP {
    margin: 0px;
    background-color: black;
    display: flex;
    flex-direction: column;
}


.MainContentSP .SPContent {
    overflow-y: auto;
}

.MainContentSP .SPContent::-webkit-scrollbar-thumb {
    background-color: red!important;
  }
  
.MainContentSP .SPContent::-webkit-scrollbar-track {
  background: #191919 !important;
  border-radius: 10px !important
}

.MainContentSP .SPContent::-webkit-scrollbar{
   width: 10px;
   height: 0px !important;
}

.MainContentSP .SPMainContentDiv {
    display: flex;
    width: 100%;
}

.SPContent .ColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.SPContent .AICenter {
    align-items: center;
}

.MainContentSP .CommonText {
    font-family: "Inter", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.CommonText.InfoTextSm {
    font-size: 20px;
    font-weight: 900;
    line-height: normal;
    color: white;
}

.InfoTextContainerSm {
    margin-top:24px;
}

.SPMainContentDiv .LgRightPanel {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
    border-left: 5px solid #212121;
    border-top: 5px solid #212121;
    border-top-left-radius: 100px;
}

.LgRightPanel .ActionButtonContainer {
    position: absolute;
    bottom: 15%;
    width: 100%;
}



@media screen and (max-width: 1079px) {
    .SPContent {
        margin-top: 48px;
    }
}

@media screen and (max-width: 599px) {
    .SPContent {
        margin-top: 32px;
    }
}