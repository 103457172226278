.OSMainPanel {
    overflow-y: auto;
}

.OSMainPanel::-webkit-scrollbar-thumb {
    background-color: red!important;
  }
  
.OSMainPanel::-webkit-scrollbar-track {
  background: #191919 !important;
  border-radius: 10px !important
}

.OSMainPanel::-webkit-scrollbar{
   width: 10px;
   height: 0px !important;
}

.OSMainPanel .ColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.OSMainPanel .AICenter {
    align-items: center;
}

.OSMainPanel .MuiButton-label {
    white-space: nowrap;
}

.OSBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 351px;
    padding: 24px 24px 129px 24px;
}
.OSBox .Avatar{
    height: 75px;
    width: 75px;
}

.OSBox .OSOBox {
    margin-top: 24px;
}

.OSBox .OSOBox .OSOText {
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.OSBox .OSSBox .OSSText {
    font-family: "Inter", sans-serif;
    font-size: 62px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}



@media screen and (max-width: 1079px) {

    .OSMainPanel {
        margin-top: 48px;
    }

    .OSMainPanel .SplitButton.Next{
        margin-top: 4px;
    }

    .OSMainPanel .ActionButtonContainerSm{
        margin-top: 4px;
    }

}

@media screen and (max-width: 599px) {

    .OSMainPanel {
        margin-top: 32px;
    }

    .OSBox {
        width: 328px;
    }
}