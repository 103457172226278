.RightPanel {
    width: 100%;
    height: 815px;
    overflow: hidden;
    position: relative;
    border-left: 5px solid #212121;
    border-top: 5px solid #212121;
    border-top-left-radius: 100px;
}

.RightPanel .ActionButtonContainer {
    position: absolute;
    bottom: 10%;
    width: 100%;
}

.RightPanel .ColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ColumnCenter.AICenter {
    align-items: center;
}

.RightPanel .CommonText {
    font-family: "Inter", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.CommonText.InfoTextLg {
    font-size: 32px;
    font-weight: 900;
    line-height: normal;
    color: white;
}


.BtnContainer .SplitButton {
    padding: 15px 127px 15px 126px;
    border-radius: 30px;
    color: white;
    font-family: "Karla", sans-serif;
    font-size: 20px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 15px;
    width: 300px;
}

.SplitButton.Next {
    background-color: #ff0000;
    margin-top:32px;
    white-space: nowrap;
}

.SplitButton.Next:hover {
    background-color: #ff0000;
}

.SplitButton.Back {
    background-color: #212121;
}

.SplitButton.Back:hover {
    background-color: #212121;
}

@media screen and (max-width: 1079px) {
    .ActionButtonContainerSm {
        margin-top: 32px;
    }
}

@media screen and (max-width: 599px) {

    .RightPanel .SplitButton {
        padding: 12px 108px;
    }
}