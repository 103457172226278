.label-profile-top-grid {
    position: relative;
    /* min-height: 30vh !important; */
    height: 240px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 600px) {
    .label-profile-top-grid {
      height: auto;
    }
  }
  .stats-margin{
    margin: 0px;
  }

  .custom-grid-item {
    margin: 0 !important; /* Remove default margin */
    height: 100%;
  }

  .label-mainBody-audio-withoutaudio{
    height: 90vh !important;
    overflow: auto;

  }

  .label-mainBody-audio-withoutaudio::-webkit-scrollbar-thumb {
    background-color: red!important;
  }
  .label-mainBody-audio-withoutaudio::-webkit-scrollbar {
    width: 7px !important;
  }

  .label-top-song{
    border: none
 }

 .creative-top-font{
   font-weight: 900 !important;
   font-family: 'Inter', sans-serif !important;
  }

 .dollar-icon{
  width: 40px !important;
  height: 40px !important;
  color: #d8d8d8 !important;
 }

 .label-top-song:hover {
   border: solid 3px #212121;
   border-radius: 5px;
 }

 .label-scroll::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.label-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.label-scroll::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

 .label-scroll {
  /* max-height: 224px !important; */
  overflow-y: auto !important;
  /* overflow-x: scroll !important; */
  /* max-width: 1500px !important; */
 }



  .label-private {
    /* padding: 71px 10px 70px 10px !important; */
    /* height: 90%; */
    margin: 15px auto;
  
  }
  .label-follow-handle {
    min-height: 20% !important;
  }

@media screen and (max-width: 600px) {
    .custom-grid-item {
        margin-top: 40px !important;
    }
    /* .label-profile-top-grid {
        height: 50vh !important;
      } */
}

@media screen and (max-width: 699px){
  .hide_in_mobile{
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .hide_in_medium_and_screen{
    display: none;
  }
}

.sevendayHeadPadding{
  padding-left: 20px;
}

@media screen and (min-width: 1550px) {
  .sevendayHeadPadding{
    padding-left: 45px;
  }
}

@media screen and (min-width: 1850px) {
  .sevendayHeadPadding{
    padding-left: 65px;
  }
}
