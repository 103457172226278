:root {
  --fontFamily: Inter !important;
}
.Music-Split-Deals {
  margin-bottom: 10px;
  cursor: pointer;
  font-family: var(--fontFamily) !important;
  width: 135px;
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: white;
  
}

.deal_timing {
  object-fit: contain;
  background-color: #212121;
  border-radius: 0 0 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
  width: 355px;
  height: 41px;
  flex-grow: 0;
}

.Deal-Expires-in-72h {
  width: 153px;
  height: 20px;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
}

.This-week {
  width: 80px;
  height: 19px;
  margin: 50px 39px 15px 301px;
  font-size: 16px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--fontFamily) !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.button_box .Make-An-Offer---Button{
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff
}
.SplitPercentageTextindeals{
  font-family: var(--fontFamily) !important;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-stretch: normal;
  
}

.musicdeal_leftside_container:hover {
  border-radius: 10px;
  background-color: #212121;
}

.selected_deal {
  margin-right: 10px;
  height: 68px;
  padding: 0px 14px 0px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 10px;
  background-color: #212121;
}
.selected_deal_active {
  margin-right: 10px;
  height: 68px;
  padding: 0px 14px 0px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 10px;
  background-color: #212121;
}

.musicdeal_leftside_container {
  margin-right: 10px;
  height: 68px;
  padding: 0px 14px 0px 18px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.boximage {
  width: 70%;
  /* height: 402px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
}

.musicdeal_right_side {
  width: 50%;
  height: 658px;
  padding: 0 2px 0 0;
  border-radius: 30px;
  border: solid 5px #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.topdealers_musicdeal_right_side {
  width: 50%;
  height: 658px;
  padding: 0 2px 0 0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
}

.musicdeal_right_side_contain {
  border-radius: 30px;
  border: solid 5px #212121;
  display: flex;
  width: 476px;
  height: 658px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  flex-direction: column;
}
.dealers_box_image{
  display: flex;
 flex-direction: column;
 justify-content: center;
  align-items: center;
 grid-gap: 10px;
gap: 10px;
}

.split {
  width: 113px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 6px 24px;
  object-fit: contain;
  border-radius: 30px;
  background-color: #212121;
  margin-left: 66%;
  margin-bottom: 13%;
  justify-content: flex-start;
  
  margin-top: 5px;
}

.Music-Split {
  width: 65px;
  height: 18px;
  flex-grow: 0;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;
  font-family: var(--fontFamily) !important;

}

.verfied {
  width: 13px;
  height: 13px;
  flex-grow: 0;
  object-fit: contain;
  margin-bottom: 2px;
}
.leftside_top_deal:hover .MuiLinearProgress-colorPrimary {
  background-color: #070707 !important; 
}
.leftside_top_deal_active .MuiLinearProgress-colorPrimary {
  background-color: #070707 !important;
}
.sold {
  width: 168px;
/* height: 76px; */
background-color: #212121;
border-radius: 30px;
display: flex;
align-items: center;
/* grid-gap: 5px; */
gap: 18px;
margin-left: -138px;
}

.logoverified {
  width: 50px;
  height: 50px;

}

.deal_elements {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.deal_elements_firstchild {
  width: 27px;
  height: 18px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--fontFamily) !important;
}
.user_type{
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #3959f9 !important;
}

.deal_elements_secondchild {
  width: 35px;
  height: 18px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;
  font-family: var(--fontFamily) !important;
}

.Musicdealtop_container {
  display: flex;
  gap: 2.9%;
  justify-content: space-between;
}

.Deal-Offers {
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  font-family: var(--fontFamily) !important;
  width: 169px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffff;
  /* height: 33px; */
  /* background-color: ; */
}

.clickedDealoofers {
  width: 169px;
  height: 33px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 60px;
  background-color: #3959f9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 800;
  font-family: var(--fontFamily) !important;
}

.DealinsideBox {
  border-radius: 30px;
  height: 47px;
  width: 355px;
  background-color: rgb(33, 33, 33);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Pending {
  width: 49px;
  height: 16px;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--fontFamily) !important;
}

.pendingStats {
  width: 27px;
  height: 16px;
  flex-grow: 0;
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.38;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;
  font-family: var(--fontFamily) !important;
}

.dealoffer_container {
  display: flex;
  justify-content: space-between;
  gap: 5px
}

.dealofferstats {
  width: 164px;
  height: 83px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 10px;
  display: flex;
  place-content: center;
  padding-left: 19px;
  gap: 20px;
  flex-direction: column;
  background-color: #121212;
}
.text_style_2{
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--fontFamily) !important;
}

.Musicdealtop_container_element {
  width: 60%;
  height: 51vh
}
.cashier{
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}
.label_text{
  font-family: var(--fontFamily) !important;
}

.OfferingdealText{
  font-family: var(--fontFamily) !important;
  /* font-size: 42px !important;
  font-weight: 800 !important; 
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.38 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #ffffff !important; */
}

.change_status {
  width: 351px;
  height: 617px;
  object-fit: contain;
  border-radius: 30px;
  border: solid 3px #212121;
  
  margin-top: 10px;
  background-color: #070707;
}

.leftside_music_deal:hover {
  /* height: 80px; */
  padding: 7px 24px 10px 16px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #212121;
}

.leftside_second_element {
  display: flex;
  justify-content: space-between;
  align-items: 'center'
  
}

.leftside_music_deal {
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  /* height: 80px; */
  padding: 7px 24px 10px 16px;
  object-fit: contain;
  border-radius: 10px;
}
.leftside_top_deal {
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  /* height: 80px; */
  padding: 7px 24px 5px 16px;
  object-fit: contain;
  border-radius: 10px;
}
.leftside_top_deal:hover{
  padding: 7px 24px 5px 16px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #212121;
}
.leftside_top_deal_active{
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  padding: 7px 24px 5px 16px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #212121;
}
.dealselection_right_box {
  display: flex;
  flex-direction: column;
  width: 98%;
  align-items: center;
  margin-top: 1%;
}

.deal_image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 60px
}

.button_box {
  display: flex;
  margin-top: 18px;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.Make-An-Offer---Button {
  width: 208px !important;
  height: 40px !important;
  flex-grow: 0;
  object-fit: contain !important;
  border-radius: 30px !important;
  background-color: #212121 !important;
  color: white !important;
}

.dashboardDeal_offer_preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 30px;
  width: 351px;
  padding: 22px 24px 129px 24px;
}

.dashboardDeal_offer_preview .LabelBox {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.dashboardDeal_offer_preview .SplitDetailBox {
  display: flex;
  justify-content: space-around;
  align-content: center;
  height: 120px;
}

.Accepted {
  width: 299px;
  height: 55px;
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffff;
  margin-top: 22%;
}

.dashboarddeal_parent_container {
  padding: 0px 52px 0px 15px
}

.musicldealtop_element_child {
  display: flex;
  justify-content: space-between;
  padding-right: 21px;
}

.musicldeal_leftside_wrapper {
  height: 693px;
  margin-top: 10px;
  overflow-x: hidden!important;
}

.musicldeal_imagebox {
  gap: 11%;
  justify-content: center;
  align-items: center;
  display: flex;
  
}
.soldTo{
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--fontFamily) !important;
}
.soldowner{
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff
}
.Negotitating {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #ff0000;
  text-transform: capitalize;
  font-family: var(--fontFamily) !important;
}

.deal_ownership_details {
  margin-left: -43%;
  align-items: center;
  display: flex;
  
  gap:14px
}




/* .deal_slider .MuiSlider-rail {

  border: 1px solid #E2007B !important
 
} */
.deal_slider .MuiSlider-track {
  height: 10px !important;
  border-radius: 5px !important;
}


.deal_slider .MuiSlider-thumb {
 
  display: none !important;
  width: 0px !important;
}

.deal_slider .MuiSlider-rail{
  height: 10px !important;
  
  background-color: #212121 !important;
  border-radius: 10px !important;
}

.dealexpriesend{
  
  text-align: left;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.deal_offer_wrapper_box{
  width: 355px;
}

.rightsidecontainer_deal_container {
  align-items: center;
  justify-content: end;
  display: flex;
 
}

.musicldeal_leftside_wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: red!important;
}

.musicldeal_leftside_wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey  !important;
  border-radius: 10px !important;
}

.musicldeal_leftside_wrapper::-webkit-scrollbar{
  height: 0px !important;
  width: 10px;
}



/* screen size */
@media screen and (max-width: 600px) {
  .Musicdealtop_container {
    gap: 346px !important;
    flex-direction: column !important;
    display: flex !important;
  }
  .leftside_music_deal{
    gap: 9px;
  }

  .dashboardDeal_offer_preview {
    width: auto;
    padding: 15px 7px 47px 24px
  }
  .button_box {
    padding: 0;

  }

  .dealselection_right_box {
    width: auto;
  }

  .rightsidecontainer_deal_container {
    justify-content: center !important;
    align-items: center;
    display: flex;
  }


  .dashboarddeal_parent_container {
    padding: 0px 4px 0px 4px;

  }


  .Musicdealtop_container_element {
    width: 100% !important;
    height: 51vh !important
  }

  .musicdeal_right_side {
    width: 100% !important;

  }

  .musicdeal_right_side_contain {
    width: auto;
  }

  .boximage {
    width: auto;
  }

}

@media screen and (max-width: 400px) {
 
  .deal_offer_wrapper_box{
    width: auto;
  }

}
@media (min-width: 601px) and (max-width: 1150px) {
  .Musicdealtop_container {
    display: flex !important;
    gap: 346px !important;
    flex-direction: column !important;
  }

  .dashboarddeal_parent_container {
    padding: 0px 4px 0px 4px;

  }

  .deal_selection_maiancontent {

    align-items: unset !important;
  }

  .rightsidecontainer_deal_container {
    justify-content: center !important;
  }


  .Musicdealtop_container_element {
    height: 51vh !important;
    width: 100% !important;
  }

  .top_dealers_musicdeal_right_side {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}