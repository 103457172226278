:root {
  --fontFmaily: Inter;
}

.listener_container {
  /* height: '100px',
    borderRadius: '10px',
    border: '1px solid #212121',
    backgroundColor: '#141414',
    margin: '5px 20px',
    alignItems: 'center', */
  height: 90px;
  border-radius: 10px;
  border: 1px solid #212121;
  background-color: #141414;
  margin: 5px 10px;
  align-items: center;
}

.listenerPrice_text {
  text-align: center;
}

.displayTitle{
  font-size: 26px;
  font-weight: 900;
  color: #ffffff;
  font-family: var(--fontFmaily);
  /* margin-left: 20px; */
  border: 2px solid #212121;
  border-radius: 10px;
  padding: 0px 20px;
  display: flex !important;
  align-items: center !important;
}

.displayParentTitle{
  background-color: black;
  border: none;
  display: flex;
  font-size: 30px;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  justify-content: left;
}

.listenerFont {
  font-size: 22px;
  font-weight: 900;
  color: #ffffff;
  font-family: var(--fontFmaily);
  /* margin-left: 20px; */
}

.title_margin {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.listenerPlanChange {
  background-color: black;
  color: white;
  border: 2px solid #212121;
  /* width: 70%; */
  width: 40%;
  display: flex;
  font-size: 30px;
  border-radius: 10px;
  font-weight: bold;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
}

.redColor {
  color: red;
  stroke-width: 3px;
  stroke: red;
}

.confirmPlan {
  background-color: black !important;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 5px solid rgb(40 40 42) !important;
  border-radius: 25px !important;
  padding: 20px;
}
.listenerPlan_text {
  display: flex;
  align-items: center;
}
.listenerPlan_Btn {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 1800px) {
  .listenerPlanChange {
    width: 45%;
  }
}

@media only screen and (max-width: 1550px) {
  .listenerPlan_Btn {
    justify-content: space-around;
  }
  .listenerPlanChange {
    width: 55%;
  }
}

@media only screen and (max-width: 1450px) {
  .listenerPlanChange {
    width: 65%;
  }
}

/* @media only screen and (max-width: 1400px) {
  .listenerPlanChange {
    width: 80%;
  }
} */

@media only screen and (max-width: 1300px) {
  .listenerPlanChange {
    width: 75%;
  }
}

@media only screen and (max-width: 1180px) {
  .listenerPlanChange {
    width: 85%;
  }
}
@media only screen and (max-width: 1050px) {
  .listenerPlanChange {
    width: 95%;
  }
}

@media only screen and (max-width: 960px) {
  .listenerPlanChange {
    width: 35%;
  }
}

@media only screen and (max-width: 700px) {
  .listenerPlanChange {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  .listener_container {
    height: 150px;
    align-content: center;
  }
  .listenerPlan_text {
    /* text-align: center; */

    display: flex;
    justify-content: center;
  }

  .listenerPlan_Btn {
    display: flex;
    justify-content: center;
  }
  .title_margin {
    margin-left: 0px;
  }

  .listenerPlanChange {
    width: 55%;
  }
}

@media only screen and (max-width: 530px) {
  .listenerPlanChange {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .listenerPlanChange {
    width: 100%;
  }
}
